import { Footer } from '@components/footer';

import { Navbar } from '@pages/@common/nav-bar';

import AppRoutes from './route/appRoutes';

export const App = () => {
  return (
    <>
      <Navbar />
      <AppRoutes />
      <Footer />
    </>
  );
};
