import { createSimpleSlice } from './utils';

import { SliceApiPersistentState } from '@app-types/api';
import { ReduxState } from '@app-types/redux';
import { dummyStatus } from '@utils/api';

const dumyPaginatedData: SliceApiPersistentState = {
  data: {
    result: {
      data: [],
      dataCount: 0,
      page: 1,
      pagesCount: 1
    },
    message: ''
  },
  status: dummyStatus
};

export const slices = {
  useAuth: createSimpleSlice<ReduxState['useAuth']>('useAuth', null),
  emergentModals: createSimpleSlice<ReduxState['emergentModals']>('emergentModals', []),
  getAllOrders: createSimpleSlice<ReduxState['getAllOrders']>('getAllOrders', dumyPaginatedData),
  solarDesignPageState: createSimpleSlice<ReduxState['solarDesignPageState']>(
    'solarDesignPageState',
    {
      ordersDto: [],
      currentOrder: null
    }
  )
};
