/* eslint-disable react/prop-types */
import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '@hooks/useAuth';

export default function ProtectedRoute({ redirectTo = '/', children }) {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to={redirectTo} replace/>;
  }
  return children ? <>{children}</> : <Outlet />;
}
